import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import Seo from "../components/seo/seo"

const InformazioniLegali = ( { data }) => {
    return (
      <Layout>
        <Seo 
          title="Privacy Policy - CTA Manufacturing"
          description="Privacy policy di CTA Manufacturing, un brand di CTA Electronics"
        />
        <section className="hero is-gradient is-medium has-text-centered">
          <div className="hero-body">
            <div className="content is-large" style={{ paddingTop: "100px" }}>
              <h1 className="title is-white">
                {data.markdownRemark.frontmatter.title}
              </h1>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div
                className="content is-justified"
                style={{
                  color: "#111",
                  lineHeight: 1.6,
                  marginLeft: "calc(+1vw)",
                  marginRight: "calc(+1vw)",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }

export default InformazioniLegali

export const infolegaliQuery = graphql`
  query LegalInfo($localizedPath: String!) {
    markdownRemark(fields: { slug: { eq: $localizedPath } }) {
      html
      frontmatter {
        lang
        path
        title
      }
    }
  }
`
